import React, { useState } from 'react';
import axios from 'axios';
const imageName = {
    1: "Application Form",
    2: "Valid ID",
    3: "Consent Form",
    4: "Showroom Photo",
    5: "House Proof",
    6: "Showroom Proof",
    7: "Current Inventory List",
    8: "Photo of Inventory",
    9: "DTI Certificate",
    10: "SEC Certificate",
    11: "Latest GIS/AOI",
    12: "Signed and Notarized Secretary's Certificate",
    13: "SEC Certificate",
    14: "Latest AOP",
    15: "Signed and Notarized Secretary's Certificate",
    16: "Bank Statement",
    17: "Sales Summary Report",
    18: "Settlement / Credit Check",
    19: "Existing Loan Agreement",
    20: "Driver’s License"
}
function checkExtension(url) {
    const extension = url ? url.split('.').pop().toLowerCase() : "";
    return ['pdf', 'csv', 'xlsx'].includes(extension);
}

async function downloadImage(imageUrl, document_id) {
  try {
    const response = await axios.get(imageUrl, { responseType: 'blob' });
    // const filename = imageUrl.split('?')[0].split('/').pop(); // Extract filename

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', imageName[document_id] + ".jpg");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading image:', error);
    // Handle download error (optional)
  }
}
const ImageGrid = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const currentImage = images?.length ? images[currentIndex] : {}

  // const handleImageClick = (imageUrl) => {
  //   // Replace 'imageUrl' with the actual URL of the image you want to download
  //   // const imageUrl = 'https://example.com/image.jpg';

  //   // Create a temporary anchor element
  //   const anchor = document.createElement('a');
  //   anchor.href = imageUrl;
  //   anchor.download = 'image.jpg'; // Specify the filename for download

  //   // Append the anchor element to the body
  //   document.body.appendChild(anchor);

  //   // Programmatically click the anchor element to trigger download
  //   anchor.click();

  //   // Remove the anchor element from the body
  //   document.body.removeChild(anchor);
  // };



  return (
    <>
    <h2 className='image-grid-card-title'>{imageName[currentImage?.document_id]}</h2>
    <div className="image-grid">
     
      <button onClick={handlePreviousImage} disabled={currentIndex === 0} className='btn btn-primary mrg-r30'>
        Previous
      </button>
      {checkExtension(currentImage?.doc_url?.[0]) && 
      <button className='btn btn-primary mrg-r30 pdf-csv-file'><a href={currentImage?.signed_url?.[0]} target='_blank' rel="noreferrer">click to open</a></button>
      }
      {!checkExtension(currentImage?.doc_url?.[0]) && (<>
      <img src={currentImage?.signed_url?.[0]} alt="Document" className='img-bx-select-opt' onClick={() => downloadImage(currentImage?.signed_url?.[0], currentImage?.document_id)} />
      </>)}
      <button onClick={handleNextImage} disabled={currentIndex === images.length - 1} className='btn btn-primary mrg-r30'>
        Next
      </button>
      
    </div>
    </>
  );
};

export default ImageGrid;